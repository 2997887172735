/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
    var windowWidth = $(window).innerWidth();

    $(window).resize(function() {
      windowWidth = $(window).innerWidth();
    });

    //Header Slider  and settings in different window sizes
    var sliderHeight = 0,
        paddingBottomSlide = 0;
    var slideHomeList = $('.slider-container .swiper-slide');

    function slideSettingsCount() {
      slideHomeList.each(function () {
        var $this = $(this);
        if ($this.innerHeight() > sliderHeight) {

          if (windowWidth < 768) {
            sliderHeight = $this.innerWidth() / 2;

            if ($this.find('.slide-info-wrap').innerHeight() > paddingBottomSlide) {
              paddingBottomSlide = $this.find('.slide-info-wrap').innerHeight();
            }
          } else {
            sliderHeight = $this.innerHeight();
            paddingBottomSlide = 0;
          }
        }
      });
    }

    function sliderSettings() {
      slideSettingsCount();

      if (windowWidth < 768) {
        $('.slider-container').css("paddingBottom", paddingBottomSlide );
        $('.slider-container .slide-info-wrap').css("top", sliderHeight);
      } else {
        $('.slider-container').css("paddingBottom", 0 );
        $('.slider-container .slide-info-wrap').css("top", 0);
      }
    }

    sliderSettings();

    var SliderSwiper = new Swiper('.slider-container', {
        // Optional parameters
        direction: 'horizontal',
        // direction: 'vertical',
        slidesPerView: 1,
        slidesPerColumn: 1,
        // spaceBetween: 0,
        slidesOffsetAfter: 0,
        loop: false,
        roundLengths: true,
        speed: 700,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            bulletElement: 'span',
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '">' + (index + 1) + '</span>';
            }
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        autoplay: {
            delay: 4000
        }
    });

    if (windowWidth < 992) {
        $('.swiper-controls').css("top", (sliderHeight - 50));
        slideHomeList.css("height", sliderHeight);
    } else {
        $('.swiper-controls').css("top", (-1));
        slideHomeList.css("height", sliderHeight);
    }
    if (windowWidth > 767) {
        slideHomeList.css("height", 'auto');
    }

    $(window).resize(function() {
      sliderHeight = 0;
      sliderSettings();

      if (windowWidth < 992) {
        $('.swiper-controls').css("top", (sliderHeight - 50));
        slideHomeList.css("height", sliderHeight);
      } else {
        $('.swiper-controls').css("top", (-1));
        slideHomeList.css("height", sliderHeight);
      }
      if (windowWidth > 767) {
        slideHomeList.css("height", 'auto');
      }
    });


    // header with image settings
    var headerWithImg = $('.with-image');

    if( headerWithImg.is('section')) {
        var h1Height = headerWithImg.find('h1').innerHeight();
        headerWithImg.css('marginBottom', (h1Height / 2));
    }

    // Featured boxes settings
    var featuredBoxItems = $('.featured-boxes .box');
    if(featuredBoxItems.length === 2 && $(window).innerWidth() >= 768) {
        featuredBoxItems.addClass('half-width');
    }

    //img settings
    var images = $('.img-wrap');

    if (windowWidth < 768) {
      images.each(function halfHeightFnc() {
        var imageWidth = this.offsetWidth;
        this.style.height = (imageWidth / 2) + 'px';
      });
    }

    $(window).resize(function() {
      if (windowWidth < 768) {
        images.each(function halfHeightFnc() {
          var imageWidth = this.offsetWidth;
          this.style.height = (imageWidth / 2) + 'px';
        });
      }
    });

    //Navigation
    var activeParent = $('.menu-item-has-children.active');
    if(activeParent.find('li.active').length !== 0) {
        activeParent.removeClass('active');
    }

    //Subnavigations
    var subnavElWrap = $('.section.subnavigation'),
        subnavEl = $('section.subnavigation');

    function subnavPosition() {

        var subnavElPositionTop = subnavElWrap.offset().top,
            topOfWindow = $(window).scrollTop(),
            subnavHeight = subnavElWrap.children().innerHeight();

        if (subnavElPositionTop < topOfWindow + 20) {
            subnavElWrap.children().addClass('full-width');
            subnavElWrap.children().css("height", subnavHeight);
            subnavEl.addClass('fixed');
        } else {
            subnavElWrap.children().removeClass('full-width');
            subnavEl.removeClass('fixed').removeClass('fixed-up');
        }
    }
    if( subnavElWrap.is('div')) {

        $(window).scroll(function () {
            subnavPosition();
        });
    }

    $('.sub-nav-link').on('click', function(event) {
        event.preventDefault();

        var id  = $(this).attr('href'),
            top = $(id).offset().top - 50;

        $('body,html').animate({scrollTop: (top)}, 500);
    });

    // Dropdown menu
    var dropdownItem = $('.menu-item-has-children');

    dropDownFnc(windowWidth);

    $(window).resize(function() {
        dropDownFnc(windowWidth);

        if( windowWidth > 991) {
            dropdownItem.height( 21 ).removeClass('mob-menu-open');
        } else {
            dropdownItem.height( 60 ).removeClass('mob-menu-open');
        }
    });

    function dropDownFnc( windowWidth ) {
        if( windowWidth > 991) {
            dropdownItem.mouseover(function() {
                var $this = $(this);
                var subMenu = $('.sub-menu');

                $this.addClass('visible');

                $this.find( subMenu ).addClass('show-dropdown');
            });

            dropdownItem.mouseleave(function () {
                dropdownItem.removeClass('visible');

                var $this = $(this);
                var subMenu = $('.sub-menu');

                $this.find(subMenu).removeClass('show-dropdown');
            });
        } else {
            var dropdownItemMob = $('.menu-item-has-children a');

            dropdownItemMob.on('click', function (e) {
                if (e.offsetX > this.offsetWidth) {
                    e.preventDefault();

                    var checkHeight = 60;
                    var parent = $(e.target).closest('.menu-item-has-children'),
                        parentHeight = $(parent).innerHeight();

                    if ( parentHeight > checkHeight ) {
                        dropdownItem.height(checkHeight).removeClass('mob-menu-open');
                    } else {
                        dropdownItem.height(checkHeight).removeClass('mob-menu-open');

                        var subMenuHeight = $(parent).find('.sub-menu').innerHeight();
                        $(parent).height(checkHeight + subMenuHeight).addClass('mob-menu-open');
                    }
                }
            });
        }
    }

})(jQuery); // Fully reference jQuery after this point.
